<!--商品计划-->
<template>
    <div class="Plan">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" v-model="form" size="small" label-width="120px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组">
                            <el-select
                                v-model="form.deptGroupCode"
                                filterable
                                :loading="loadingDeptGroupFlag"
                                style="width: 180px"
                            >
                                <el-option value="" label="请选择" v-if="meta.groups && meta.groups.length > 1" />
                                <el-option
                                    v-for="group in meta.groups"
                                    :value="group.code"
                                    :key="group.code"
                                    :label="group.name"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="商品名称">
                            <el-input placeholder="关键字" v-model="form.search" style="width: 180px" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="启用状态">
                            <el-select placeholder="请选择" v-model="form.status" style="width: 180px">
                                <el-option label="请选择" value="" />
                                <el-option label="启用" :value="1" />
                                <el-option label="停用" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="创建日期-开始">
                            <el-date-picker
                                type="date"
                                v-model="form.startTime"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                style="width: 180px"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期-结束">
                            <el-date-picker
                                type="date"
                                v-model="form.endTime"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                style="width: 180px"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button @click="handleNewPromotion"> 新促销活动页面 </el-button>
            <el-button type="primary" @click="handleQuery" size="small" v-if="hasPrivilege('menu.plan.goods.open')"
                >查询
            </el-button>
            <el-button type="primary" @click="handleCreate" size="small" v-if="hasPrivilege('menu.plan.goods.create')">
                新建
            </el-button>
            <el-button type="primary" @click="handleExport" size="small" v-if="hasPrivilege('menu.plan.goods.export')">
                导出
            </el-button>
            <el-button type="primary" @click="handleOn" size="small" v-if="hasPrivilege('menu.plan.goods.edit')"
                >批量启用
            </el-button>
            <el-button type="primary" @click="handleOff" size="small" v-if="hasPrivilege('menu.plan.goods.edit')"
                >批量停用
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                ref="table"
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="450"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column
                    label="机构组名称"
                    width="120"
                    prop="deptGroupName"
                    v-if="showColumn('deptGroupName')"
                />
                <el-table-column label="计划名称" width="140" prop="name" v-if="showColumn('name')" />
                <el-table-column label="商品名称" width="140" prop="goodsName" v-if="showColumn('goodsName')" />
                <el-table-column label="在库商品" width="100" prop="type" v-if="showColumn('type')" key="type">
                    <template slot-scope="scope">
                        <span>{{ scope.row.type == 1 ? '在库' : '不在库' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="启用状态" width="100" prop="status" v-if="showColumn('status')" key="status">
                    <template slot-scope="scope">
                        <span>{{ scope.row.status | status }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="配送类型"
                    width="100"
                    prop="deliveryType"
                    v-if="showColumn('deliveryType')"
                    key="deliveryType"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.deliveryType == 1 ? '自取' : '配送' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="开始时间" width="140" prop="startDate" v-if="showColumn('startDate')" />
                <el-table-column label="结束时间" width="140" prop="endDate" v-if="showColumn('endDate')" />
                <el-table-column label="创建时间" width="140" prop="createTime" v-if="showColumn('createTime')" />
                <el-table-column
                    label="操作"
                    width="280"
                    header-align="center"
                    size="mini"
                    v-if="showColumn('operate')"
                    key="operate"
                    prop="operate"
                >
                    <template slot-scope="scope">
                        <el-button size="small" @click="rowDetail(scope.row)">方案</el-button>
                        <el-button size="small" @click="rowSummary(scope.row)">统计</el-button>
                        <el-button size="small" type="warning" @click="handleBindDept(scope.row)">机构</el-button>
                        <el-button size="small" type="danger" @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
export default {
    name: 'Programme',
    components: { CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                code: '',
                status: '',
                deptCode: '',
                deptGroupCode: '',
                search: '',
                reviewStatus: '',
                enableFlag: '',
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            loadingDeptGroupFlag: true,
            url: {
                queryGroups: '/system/deptGroup/staffManagedAllDeptGroups',
                page: '/merchandising/pageList',
                delete: '/merchandising/delete',
                handleOnAndOff: '/merchandising/enableAndDisable',
            },
            meta: {
                groups: [],
                reviewEnums: Util.reviewStatusEnum(true),
            },
        };
    },
    mounted() {
        UrlUtils.DeptGroup(this, (rst) => {
            this.meta.groups = rst || [];
            if (this.meta.groups.length == 1) {
                this.form.deptGroupCode = this.meta.groups[0].code;
            }
            this.loadingDeptGroupFlag = false;
            this.handleQuery();
        });
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        handleNewPromotion() {
            this.$router.push({ name: 'promotion' });
        },
        handleOn() {
            const _this = this;
            const _a = [];
            let vaildStatus = false;
            if (_this.$refs['table'].selection == 0) {
                _this.$message.error('请选择启用单据');
                return;
            }
            _this.$refs['table'].selection.forEach((v) => {
                if (v.status != 2) {
                    vaildStatus = true;
                }
                _a.push(v.code);
            });
            if (vaildStatus) {
                _this.$message.error('请选择停用状态单据进行启用');
                return;
            }
            const _params = { codes: _a, status: 1 };
            UrlUtils.PatchRemote(_this, _this.url.handleOnAndOff, _params, null, function () {
                _this.handleQuery();
                _this.$message.success('保存成功');
            });
        },
        handleOff() {
            const _this = this;
            const _a = [];
            let vaildStatus = false;
            if (_this.$refs['table'].selection == 0) {
                _this.$message.error('请选择停用单据');
                return;
            }
            _this.$refs['table'].selection.forEach((v) => {
                if (v.status != 1) {
                    vaildStatus = true;
                }
                _a.push(v.code);
            });
            if (vaildStatus) {
                _this.$message.error('请选择启用状态单据进行停用');
                return;
            }
            const _params = { codes: _a, status: 2 };
            UrlUtils.PatchRemote(_this, _this.url.handleOnAndOff, _params, null, function () {
                _this.handleQuery();
                _this.$message.success('保存成功');
            });
        },

        handleDelete(row) {
            UrlUtils.DeleteRemote(this, this.url.delete, row.code);
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },

        handleCreate() {
            Util.nameJump(this, 'menu.plan.goods.create', ['促销管理', '商品计划', '新建计划']);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.plan.goods.detail', ['促销管理', '商品计划', '计划方案'], {
                form: row,
                code: row.code,
            });
        },
        rowSummary(row) {
            Util.nameJump(this, 'menu.plan.goods.summary', ['促销管理', '商品计划', '计划统计'], {
                code: row.code,
                form: row,
            });
        },
        handleBindDept(row) {
            Util.nameJump(this, 'menu.plan.goods.goodsPlanDeptDetail', ['促销管理', '商品计划', '商品计划机构列表'], {
                form: row,
                code: row.code,
            });
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '商品计划', '/merchandising/export', this.form, codes);
        },
    },
    filters: {
        ruleType(type) {
            switch (type) {
                case 1: {
                    return '单品折扣';
                }
                case 2: {
                    return '单品偶数折扣';
                }
                case 3: {
                    return '单品特价';
                }
            }
        },
        status(status) {
            switch (status) {
                case 1: {
                    return '启用';
                }
                case 2: {
                    return '停用';
                }
            }
        },
    },
};
</script>

<style scoped>
.Plan .el-form-item {
    margin-bottom: 0;
}
</style>
